<template>
    <div class="app-container">
        <ToolBar :dicts="dicts" @on-change="dealWithToolBarChange" :isOrg="isOrg"></ToolBar>
        <CustomTable :dicts="dicts" :queryParams="queryParams" :isOrg="isOrg"></CustomTable>
    </div>
</template>

<script>
    import ToolBar from "./ToolBar";
    import CustomTable from "./CustomTable";
    import { mapGetters,mapState } from 'vuex'
    import {GET_DICTS} from '@/store/constants';
    export default {
        name: "BWListManage",
        components: {CustomTable, ToolBar},
        computed: {
            ...mapGetters({
                dicts: GET_DICTS
            }),
            ...mapState('app', ['userInfo']),
            isOrg(){
                return this.userInfo.org
            },
        },
        data(){
            return{
                queryParams: {},
            }
        },
        methods: {
            dealWithToolBarChange(params) {
                this.queryParams = params;
            },
        }
    }
</script>

<style scoped>

</style>
