<template>
    <div>
        <el-form ref="queryForm" class="query-box" v-model="queryParams" :inline="true">
            <el-form-item :label="isOrg?'代理商/机构编号':'代理商编号'" prop="agentNo" label-width="120px">
                <el-input v-model="queryParams.agentNo" clearable :placeholder="`请输入${isOrg?'代理商/机构编号':'代理商编号'}`" style="width: 240px" size="small"
                    @keyup.enter.native="handleQuery" />
            </el-form-item>
            <el-form-item :label="isOrg?'代理商/机构名称':'代理商名称'" prop="userName" label-width="120px">
                <el-input v-model="queryParams.userName" clearable :placeholder="`请输入${isOrg?'代理商/机构名称':'代理商名称'}`" style="width: 240px" size="small"
                    @keyup.enter.native="handleQuery" />
            </el-form-item>
            <el-form-item label="调价类型" prop="adjustType" label-width="120px">
                <el-select v-model="queryParams.adjustType" placeholder="请选择调价类型" clearable size="small" style="width: 240px">
                    <el-option v-for="(value, key) in dicts.ADJUST_TYPE" :label="value" :value="key" :key="key">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="加价类型" prop="adjustNo" label-width="120px">
                <el-select v-model="queryParams.adjustNo" placeholder="请选择加价类型" clearable size="small" style="width: 240px">
                    <el-option v-for="(value, key) in dicts.ADJUST_NO" :label="value" :value="key" :key="key">
                    </el-option>
                </el-select>
            </el-form-item>
        </el-form>

        <el-row :gutter="10" class="mb8">
            <el-col :span="1.5">
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery"
                    v-permission="'AGENT_ADJUST_COST_QUERY'">
                    查询
                </el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="handleResetQuery">
                    重置
                </el-button>
                <el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd"
                    v-permission="'AGENT_ADJUST_COST_UPDATE'">
                    新增
                </el-button>
            </el-col>
        </el-row>

        <CustomForm :dicts="dicts" :on-fresh="showCustomForm" @on-close="handlerCustomFormOnclose" :isAdd="true">
        </CustomForm>
    </div>
</template>

<script>
import CustomForm from "./CustomForm";
import { AgentApi } from '@/api';
export default {
    name: "ToolBar",
    components: { CustomForm },
    props: {
        dicts: {
            type: Object,
            default: {}
        },
        isOrg:{
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            queryParams: {},
            createDateRange: [],
            updateDateRange: [],
            showCustomForm: false,
        }
    },
    mounted() {
    },
    methods: {
        handlerCustomFormOnclose() {
            this.showCustomForm = false;
            this.commitChange();
        },
        handleQuery() {
            this.commitChange();
        },
        handleResetQuery() {
            this.queryParams = {};
            this.createDateRange = [];
            this.updateDateRange = [];
            this.commitChange();
        },
        handleAdd() {
            this.showCustomForm = true;
        },
        handleExport() {
            this.$confirm("确定导出黑白名单配置",
                "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(async () => {
                this.dealWithQueryParams();
                let result = (await AgentApi.agentAdjustCost.export(this.queryParams));
                if (result.success) {
                    this.downloadFile(result.data);
                }
            });
        },
        dealWithQueryParams() {
            let [startCreateTime, endCreateTime] = this.createDateRange;
            this.queryParams.startCreateTime = startCreateTime && (startCreateTime + ' 00:00:00');
            this.queryParams.endCreateTime = endCreateTime && (endCreateTime + ' 23:59:59');

            let [startUpdateTime, endUpdateTime] = this.updateDateRange;
            this.queryParams.startUpdateTime = startUpdateTime && (startUpdateTime + ' 00:00:00');
            this.queryParams.endUpdateTime = endUpdateTime && (endUpdateTime + ' 23:59:59');
        },
        commitChange() {
            this.dealWithQueryParams();
            let params = { ...this.queryParams };
            this.$emit('on-change', params);
        },
        handleMerchant(){
            this.$router.push({
                name:'businessMerchantRateAdjustRecord'
            })
        }
    }
}
</script>

<style scoped></style>
